<template>
  <div>
    <vs-sidebar click-not-close parent="#post-app" :hidden-background="true" class="full-vs-sidebar post-view-sidebar items-no-padding" v-model="isKategoriAddActive" position-right>
      <div class="px-0 sm:pb-6 h-full post-area-loading">
        <div class="flex flex-wrap items-center justify-between md:px-8 px-6 py-5 d-theme-dark-bg post-header bg-primary">
          <h5 class="text-white">Tambah Kategori</h5>
          <feather-icon :icon="$vs.rtl ? 'XIcon' : 'XIcon'" @click="kategoriAddClose" class="cursor-pointer mr-4" svg-classes="text-white w-6 h-6"></feather-icon>
        </div>

        <component :is="scrollbarTag" class="post-scroll-area md:p-8 p-6 mt-base" :settings="settings" :key="$vs.rtl">
          <div class="vx-col w-full sm:w-1/2 items-center sm:flex-row flex-col mx-auto">
            <div class="vx-row mb-6">
              <div class="vx-col w-full">
                <vs-input class="w-full" label="Nama Kategori" v-model="dataKategori" />
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-1/2">
                <vs-button type="border" color="danger" @click="kategoriAddClose">Cancel</vs-button>
              </div>
              <div class="vx-col w-1/2 flex justify-end">
                <vs-button class="mr-4 mb-2" :disabled="!isFormValid" @click="submitData" color="primary">Submit</vs-button>
              </div>
            </div>
          </div>
        </component>
      </div>
    </vs-sidebar>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
export default{
  props: {
    isKategoriAddActive: {
      type: Boolean,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar
  },
  data () {
    return {
      settings : {
        maxScrollbarLength : 60,
        wheelSpeed         : 0.30
      },
      dataKategori: null,
    }
  },
  watch: {
    isKategoriAddActive (value) {
      if (!value) return
    }
  },
  computed: {
    scrollbarTag () { 
      return this.$store.getters.scrollbarTag
    },
    windowWidth ()  { 
      return this.$store.state.windowWidth                 
    },
    isFormValid () {
      return this.dataKategori != ''
    },
  },
  methods: {
    initValues() {
      this.dataKategori = null
    },
    kategoriAddClose() {
      this.$emit('kategoriCloseAdd')
    },
    submitData () {
      this.$vs.loading()
      const obj = {
        name: this.dataKategori,
        term_group: 0,
        parent: 0,

        taxonomy: 'post_kategori'
      }

      this.$store.dispatch('post/addKategori', obj)
      .then((response) => {
        this.$vs.loading.close()
        this.$vs.notify({
          title: 'Berhasil',
          color  : 'success',
          text  : response.message,
        })
      })
      .catch(error => {
        this.$vs.loading.close()
        let {data} = error.response
        this.$vs.notify({
          title: 'Error',
          text: data.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
      this.initValues()
    }
  }
}

</script>