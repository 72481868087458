
export default {
  posts: [],
  postTags: [],
  postFilter: null,
  postKategoris: [],
  postAlbums: [],
  total: null,
  last: null,
  per_page: null,
  current_page: null
}
