
export default {
  SET_NULL_TASKS (state) {
    state.posts = []
  },

  // TASK
    SET_POSTS (state, posts) {
      state.posts = posts
    },
    SET_TOTALS (state, payload) {
      state.total = payload
    },
    SET_LAST (state, payload) {
      state.last = payload
    },
    SET_PER_PAGE (state, payload) {
      state.per_page = payload
    },
    SET_CURRENT_PAGE (state, payload) {
      state.current_page = payload
    },
    ADD_UPDATE (state, item) {
      state.posts.unshift(item)
    },
    EDIT_UPDATE (state, item) {
      //alert(item.id_before)
      const postIndex = state.posts.findIndex((p) => p.id === parseInt(item.id_before))
      Object.assign(state.posts[postIndex], item)
    },
    REMOVE_POST (state, id) {
      const ItemIndex = state.posts.findIndex((p) => p.id === id)
      state.posts.splice(ItemIndex, 1)
    },

  // KATEGORI
    SET_KATEGORIS (state, kategori) {
      state.postKategoris = kategori
    },
    ADD_KATEGORI (state, item) {
      state.postKategoris.push(item)
    },

  // ALBUM
    SET_ALBUMS (state, album) {
      state.postAlbums = album
    },
    ADD_ALBUM (state, item) {
      state.postAlbums.push(item)
    },

  // TAG
    SET_TAGS (state, tags) {
      state.postTags = tags
    },
    ADD_TAG (state, item) {
      state.postTags.push(item)
    },
    UPDATE_TAGS (state, payload) {
      state.posts.find((post) => post.id === payload.id).tags = payload.tags
    }

}
